<div class="complain-form-container d-flex flex-column justify-content-around">
  <section class="page-text">
    <div class="page-text-primary">{{ 'COMPLAIN_SUGGEST.TITLE' | translate }}</div>
    <div class="page-text-secondary">{{ 'COMPLAIN_SUGGEST.SUB_TITLE' | translate }}</div>
  </section>
  <section class="complaint-form">
    <form (ngSubmit)="onSubmit()" #complaintForm="ngForm">
      <div class="form-group">
        <label for="complaintReason">
          {{ 'COMPLAIN_SUGGEST.SELECT_COMPLAINT_REASON' | translate }}
        </label>
        <select
          class="form-control"
          id="complaintReason"
          [(ngModel)]="model.complaintReason"
          name="complaintReason"
          appHoverStyle
          #complaintReason="ngModel"
        >
          <option *ngFor="let reason of complaintReasons" [value]="reason.complaintId">
            {{ reason.complaintTranslationKey | translate }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="complaintDetails">{{ 'COMPLAIN_SUGGEST.ADD_DETAILS' | translate }}</label>
        <textarea
          type="text"
          class="form-control"
          id="complaintDetails"
          required
          [(ngModel)]="model.complaintDetails"
          name="complaintDetails"
          #complaintDetails="ngModel"
        ></textarea>
        <div
          *ngIf="complaintDetails.invalid && (complaintDetails.dirty || complaintDetails.touched)"
          class="alert alert-danger"
        >
          <div *ngIf="complaintDetails.errors?.required">
            {{ 'COMPLAIN_SUGGEST.REQUIRED_FIELD' | translate }}
          </div>
        </div>
      </div>
      <section class="submit-button d-flex justify-content-center">
        <button
          appHoverStyle
          [ngClass]="{
            'disabled-button': model.complaintDetails.length === 0 || !model?.complaintReason
          }"
          [disabled]="model.complaintDetails.length === 0 || !model?.complaintReason"
          type="submit"
        >
          {{ 'COMPLAIN_SUGGEST.SUBMIT' | translate }}
        </button>
      </section>
    </form>
  </section>
</div>
