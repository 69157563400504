<div class="complain-suggest-choice-container d-flex flex-column justify-content-around">
  <section class="page-text">
    <div class="page-text-primary">{{ 'COMPLAIN_SUGGEST.TITLE' | translate }}</div>
    <div class="page-text-secondary">{{ 'COMPLAIN_SUGGEST.SUB_TITLE' | translate }}</div>
  </section>
  <section class="complain-suggest-selection d-flex justify-content-center">
    <div
      class="select-action-button complain-request"
      appHoverStyle
      (click)="onSelectAction(complaintAction)"
      [ngClass]="{ 'action-marked': selectedAction === complaintAction }"
    >
      <div
        class="selected-checkbox d-flex justify-content-center align-items-center"
        [ngClass]="{ 'selected-checkbox-marked': selectedAction === complaintAction }"
      >
        <i class="icon icon-tick"></i>
      </div>

      <i
        [ngClass]="{
          'action-selected': selectedAction === complaintAction,
          'action-not-selected': selectedAction !== complaintAction
        }"
        class="icon icon-complain-icon complain-suggest-selection__icon"
      ></i>
      <div
        class="button-text"
        [ngClass]="{
          'action-selected': selectedAction === complaintAction,
          'action-not-selected': selectedAction !== complaintAction
        }"
      >
        {{ 'COMPLAIN_SUGGEST.SUBMIT_COMPLAINT_LABEL' | translate }}
      </div>
    </div>
    <div
      class="select-action-button suggest-request"
      appHoverStyle
      (click)="onSelectAction(suggestionAction)"
      [ngClass]="{ 'action-marked': selectedAction === suggestionAction }"
    >
      <div
        class="selected-checkbox d-flex justify-content-center align-items-center"
        [ngClass]="{ 'selected-checkbox-marked': selectedAction === suggestionAction }"
      >
        <i class="icon icon-tick"></i>
      </div>
      <i
        [ngClass]="{
          'action-selected': selectedAction === suggestionAction,
          'action-not-selected': selectedAction !== suggestionAction
        }"
        class="icon icon-suggest-icon complain-suggest-selection__icon"
      ></i>
      <div
        class="button-text"
        [ngClass]="{
          'action-selected': selectedAction === suggestionAction,
          'action-not-selected': selectedAction !== suggestionAction
        }"
      >
        {{ 'COMPLAIN_SUGGEST.SUBMIT_SUGGESTION_LABEL' | translate }}
      </div>
    </div>
  </section>
  <section class="next-button d-flex justify-content-center">
    <button
      appHoverStyle
      class="d-flex justify-content-center align-items-center"
      (click)="onPressNext()"
    >
      <p>{{ 'COMPLAIN_SUGGEST.NEXT' | translate }}</p>
      <i class="icon icon-dukan-icons-back-en"></i>
    </button>
  </section>
</div>
