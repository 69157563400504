import { Component, EventEmitter, Output } from '@angular/core';

import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { COMPLAINT } from 'src/app/presentation/shared/constants';
import { ComplainSuggestionService } from 'src/app/presentation/shared/services/complain-suggestion.service';
import { SUGGESTION_ACTION } from '../../shared/constants/complaints';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['./suggestion-form.component.scss'],
  standalone: true,
  imports: [FormsModule, HoverStyleDirective, NgFor, NgIf, NgClass, TranslateModule],
})
export class SuggestionFormComponent {
  @Output() sendSuggestion = new EventEmitter();

  public suggestionReasons = COMPLAINT.COMPLAINTS;

  public model = { suggestionReason: '', suggestionDetails: '' };

  constructor(private complainSuggestionService: ComplainSuggestionService) {}

  onSubmit(): void {
    if (this.model.suggestionDetails && this.model.suggestionReason) {
      this.complainSuggestionService
        .createComplainOrSuggestion({
          type: SUGGESTION_ACTION,
          suggestionSection: this.model.suggestionReason,
          details: this.model.suggestionDetails,
        })
        .subscribe({
          next: () => {
            this.sendSuggestion.emit();
          },
          error: (err) => {},
        });
    }
  }
}
