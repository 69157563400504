<div
  class="complaint-submitted-container d-flex flex-column justify-content-center align-items-center"
>
  <img loading="lazy" class="center-image" src="../../../../assets/img/paper-plane.svg" />
  <section class="text-section">
    <p class="submitted-text">{{ 'COMPLAIN_SUGGEST.SUGGESTION_SUBMITTED' | translate }}</p>
    <p class="additional-text">
      <!-- هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها ولذلك يتم استخدام  طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام "هنا يوجد محتوى نصي -->
    </p>
  </section>
  <button class="back-to-main-button" appHoverStyle (click)="backToMain()">
    {{ 'COMPLAIN_SUGGEST.BACK_TO_MAIN_PAGE' | translate }}
  </button>
</div>
