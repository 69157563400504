import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { environment } from 'src/environments/environment';
import { BasePresenter } from '../../base/base.presenter';
import { TALLY_COMPLAIN_SUGGEST } from '../../shared/constants/feature-flags';
import { ComplainSuggestSideEffect } from './complain-suggest.side-effects';
import { ComplainSuggestViewEvents } from './complain-suggest.view-events';
import { ComplainSuggestViewState } from './complain-suggest.view-state';

@Injectable()
export class ComplainSuggestPresenter extends BasePresenter<
  ComplainSuggestViewState,
  ComplainSuggestViewEvents,
  ComplainSuggestSideEffect
> {
  constructor(private _getFeatureFlagUseCase: GetFeatureFlagUsecase) {
    super();
  }

  protected defaultViewState(): ComplainSuggestViewState {
    return {
      loading: true,
      shouldDisplayTallyForm: false,
      tallyUrl: '',
    };
  }

  protected onViewEvent(event: ComplainSuggestViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._getFeatureFlagUseCase.execute(TALLY_COMPLAIN_SUGGEST).subscribe((flag) => {
          this.updateViewState({
            ...this.viewState,
            loading: false,
            shouldDisplayTallyForm: flag,
            tallyUrl: `https://tally.so/embed/${environment.COMPLAIN_SUGGEST_TALLY_FORM_ID}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&merchantId=${user.id}&merchantName=${user.fullName}&merchantEmail=${user.email}`,
          });
        });
        break;
      }
    }
  }
}
