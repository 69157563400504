<div class="suggest-form-container d-flex flex-column justify-content-around">
  <section class="page-text">
    <div class="page-text-primary">{{ 'COMPLAIN_SUGGEST.TITLE' | translate }}</div>
    <div class="page-text-secondary">{{ 'COMPLAIN_SUGGEST.SUB_TITLE' | translate }}</div>
  </section>
  <section class="suggestion-form">
    <form (ngSubmit)="onSubmit()" #suggestionForm="ngForm">
      <div class="form-group">
        <label for="suggestionReason">
          {{ 'COMPLAIN_SUGGEST.SELECT_SUGGESTION_TYPE' | translate }}
        </label>
        <select
          class="form-control"
          id="suggestionReason"
          [(ngModel)]="model.suggestionReason"
          name="suggestionReason"
          appHoverStyle
          #suggestionReason="ngModel"
        >
          <option *ngFor="let reason of suggestionReasons" [value]="reason.complaintId">
            {{ reason.complaintTranslationKey | translate }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="suggestionDetails">{{ 'COMPLAIN_SUGGEST.ADD_DETAILS' | translate }}</label>
        <textarea
          type="text"
          class="form-control"
          id="suggestionDetails"
          required
          [(ngModel)]="model.suggestionDetails"
          name="suggestionDetails"
          #suggestionDetails="ngModel"
        ></textarea>
        <div
          *ngIf="
            suggestionDetails.invalid && (suggestionDetails.dirty || suggestionDetails.touched)
          "
          class="alert alert-danger"
        >
          <div *ngIf="suggestionDetails.errors!.required">
            {{ 'COMPLAIN_SUGGEST.REQUIRED_FIELD' | translate }}
          </div>
        </div>
      </div>
      <section class="submit-button d-flex justify-content-center">
        <button
          appHoverStyle
          [ngClass]="{
            'disabled-button': model.suggestionDetails.length === 0 || !model?.suggestionReason
          }"
          [disabled]="model.suggestionDetails.length === 0 || !model?.suggestionReason"
          type="submit"
        >
          {{ 'COMPLAIN_SUGGEST.SUBMIT' | translate }}
        </button>
      </section>
    </form>
  </section>
</div>
