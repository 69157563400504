import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { provideRouter } from '@angular/router';
import { ComplainSuggestMainComponent } from './complain-suggest-main/complain-suggest-main.component';
import { ComplainSuggestComponent } from './complain-suggest.component';
import { ComplaintFormComponent } from './complaint-form/complaint-form.component';
import { ComplaintSubmittedComponent } from './complaint-submitted/complaint-submitted.component';
import { SuggestionFormComponent } from './suggestion-form/suggestion-form.component';
import { SuggestionSubmittedComponent } from './suggestion-submitted/suggestion-submitted.component';

@NgModule({
  imports: [
    CommonModule,
    ComplainSuggestComponent,
    ComplainSuggestMainComponent,
    ComplaintFormComponent,
    SuggestionFormComponent,
    ComplaintSubmittedComponent,
    SuggestionSubmittedComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: ComplainSuggestComponent,
      },
    ]),
  ],
})
export class ComplainSuggestModule {}
