<loader size="lg" [loading]="viewState.loading"></loader>

<ng-container *ngIf="!viewState.loading">
  <div class="complain-suggest container">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-baseline">
        <section class="page-title">{{ 'COMPLAIN_SUGGEST.MAIN_TITLE' | translate }}</section>

        <div
          *ngIf="selectedPage === 'complaint-form' || selectedPage === 'suggestion-form'"
          appHoverStyle
          (click)="backToMain()"
          class="back-button"
        >
          <img loading="lazy" src="../../../assets/img/order-cancel.svg" />
        </div>
      </div>
      <div *ngIf="viewState.shouldDisplayTallyForm; else complainSuggest" class="tally-form">
        <iframe
          [attr.data-tally-src]="viewState.tallyUrl"
          loading="lazy"
          width="100%"
          height="1150"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="الشكاوى والمقترحات"
        ></iframe>
      </div>
      <ng-template #complainSuggest>
        <app-complain-suggest-main
          *ngIf="selectedPage === 'complain-suggest-main'"
          (nextPressed)="nextPressed($event)"
          [selectedAction]="selectedAction"
        ></app-complain-suggest-main>

        <app-complaint-form
          *ngIf="selectedPage === 'complaint-form'"
          (sendComplaint)="onSendComplaintPressed()"
        ></app-complaint-form>

        <app-suggestion-form
          *ngIf="selectedPage === 'suggestion-form'"
          (sendSuggestion)="onSendSuggestionPressed()"
        ></app-suggestion-form>

        <app-complaint-submitted
          *ngIf="selectedPage === 'complaint-submitted'"
        ></app-complaint-submitted>

        <app-suggestion-submitted
          *ngIf="selectedPage === 'suggestion-submitted'"
        ></app-suggestion-submitted>
      </ng-template>
    </div>
  </div>
</ng-container>
