import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-suggestion-submitted',
  templateUrl: './suggestion-submitted.component.html',
  styleUrls: ['./suggestion-submitted.component.scss'],
  standalone: true,
  imports: [HoverStyleDirective, TranslateModule],
})
export class SuggestionSubmittedComponent {
  constructor(
    private router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  backToMain(): void {
    this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
  }
}
