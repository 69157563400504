import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import {
  COMPLAINT_ACTION,
  COMPLAINT_FORM_PAGE,
  COMPLAINT_SUBMITTED_PAGE,
  COMPLAIN_SUGGEST_MAIN_PAGE,
  SUGGESTION_ACTION,
  SUGGESTION_FORM_PAGE,
  SUGGESTION_SUBMITTED_PAGE,
} from '../shared/constants/complaints';
import { HoverStyleDirective } from '../shared/directives/hover-style.directive';
import { TallyService } from '../shared/services/tally.service';
import { ComplainSuggestMainComponent } from './complain-suggest-main/complain-suggest-main.component';
import { ComplaintFormComponent } from './complaint-form/complaint-form.component';
import { ComplaintSubmittedComponent } from './complaint-submitted/complaint-submitted.component';
import { ComplainSuggestPresenter } from './presentation/complain-suggest.presenter';
import { ComplainSuggestSideEffect } from './presentation/complain-suggest.side-effects';
import { ComplainSuggestViewEvents } from './presentation/complain-suggest.view-events';
import { ComplainSuggestViewState } from './presentation/complain-suggest.view-state';
import { SuggestionFormComponent } from './suggestion-form/suggestion-form.component';
import { SuggestionSubmittedComponent } from './suggestion-submitted/suggestion-submitted.component';

@Component({
  selector: 'app-complain-suggest',
  templateUrl: './complain-suggest.component.html',
  styleUrls: ['./complain-suggest.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    HoverStyleDirective,
    ComplainSuggestMainComponent,
    ComplaintFormComponent,
    SuggestionFormComponent,
    ComplaintSubmittedComponent,
    SuggestionSubmittedComponent,
    TranslateModule,
    LoaderComponent,
  ],
  providers: [ComplainSuggestPresenter],
})
export class ComplainSuggestComponent extends BaseComponent<
  ComplainSuggestPresenter,
  ComplainSuggestViewState,
  ComplainSuggestViewEvents,
  ComplainSuggestSideEffect
> {
  public selectedPage = COMPLAIN_SUGGEST_MAIN_PAGE;

  public selectedAction = COMPLAINT_ACTION;

  public readonly complaintAction = COMPLAINT_ACTION;

  public readonly suggestionAction = SUGGESTION_ACTION;

  public presenter: ComplainSuggestPresenter = inject(ComplainSuggestPresenter);

  private _tallyService: TallyService = inject(TallyService);

  public onInit(): void {
    this._tallyService.loadWidget();
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  nextPressed(action: string): void {
    this.selectedAction = action;
    if (action === COMPLAINT_ACTION) {
      this.selectedPage = COMPLAINT_FORM_PAGE;
    } else if (action === SUGGESTION_ACTION) {
      this.selectedPage = SUGGESTION_FORM_PAGE;
    }
  }

  onSendComplaintPressed(): void {
    this.selectedPage = COMPLAINT_SUBMITTED_PAGE;
  }

  onSendSuggestionPressed(): void {
    this.selectedPage = SUGGESTION_SUBMITTED_PAGE;
  }

  backToMain(): void {
    this.selectedPage = COMPLAIN_SUGGEST_MAIN_PAGE;
  }
}
