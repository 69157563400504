import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { COMPLAINT_ACTION, SUGGESTION_ACTION } from '../../shared/constants/complaints';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-complain-suggest-main',
  templateUrl: './complain-suggest-main.component.html',
  styleUrls: ['./complain-suggest-main.component.scss'],
  standalone: true,
  imports: [HoverStyleDirective, NgClass, TranslateModule],
})
export class ComplainSuggestMainComponent implements OnInit {
  @Input() selectedAction = COMPLAINT_ACTION;

  @Output() nextPressed = new EventEmitter<string>();

  public actions: {
    label: string;
    value: string;
  }[];

  public complaintAction: string;

  public suggestionAction: string;

  ngOnInit(): void {
    this.complaintAction = COMPLAINT_ACTION;
    this.suggestionAction = SUGGESTION_ACTION;
  }

  onSelectAction(action: any): void {
    if (action === COMPLAINT_ACTION || action === SUGGESTION_ACTION) {
      this.selectedAction = action;
    }
  }

  onPressNext(): void {
    this.nextPressed.emit(this.selectedAction);
  }
}
