import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ComplainSuggestionService {
  constructor(private http: HttpClient) {}

  createComplainOrSuggestion(data: any): Observable<any> {
    return this.http.post(API_URLS.CREATE_COMPLAIN_OR_SUGGESTION_URL, data);
  }
}
